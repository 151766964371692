import React, { Component } from 'react';
import { Grid, Container, Responsive } from 'semantic-ui-react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import HeaderWrapper from './header.style';
import Form from '../Form/form';
import CredibilityLogos from '../CredibilityLogos/CredibilityLogos';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { logo, url, company, credibility } = this.context;
    return (
      <div className='App'>
        <HeaderWrapper>
          <header className='main-header'>
            <Container>
              <Grid columns='two'>
                <Grid.Row>
                  <Grid.Column>
                    <a href={url}>
                      <img src={logo} alt={company} className='logo' />
                    </a>
                  </Grid.Column>
                  {/* <Grid.Column className='contact-number'></Grid.Column> */}
                </Grid.Row>
              </Grid>
              <h1>Compare Personal Loan Offers in Minutes!</h1>
              <Form />
              <Responsive as='div' minWidth={835}>
                <CredibilityLogos logos={credibility} />
              </Responsive>
            </Container>
          </header>
        </HeaderWrapper>
      </div>
    );
  }
}

Header.contextType = ConfigContext;

export default Header;
