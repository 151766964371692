import React from 'react';
import { Container } from 'semantic-ui-react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import FooterWrapper from './footer.style';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { logo, company, copyright, address } = this.context;
    return (
      <div className='App'>
        <FooterWrapper>
          <footer>
            <Container>
              <img src={logo} alt={company} className='logo' />
              <p>
                <a href='/privacy-policy' target='_blank'>
                  Privacy Policy
                </a>
                |
                <a href='/terms-conditions' target='_blank'>
                  Terms & Conditions
                </a>
                |
                <a href='/do-not-sell' target='_blank'>
                  California - Do Not Sell My Personal Information
                </a>
              </p>
              <p>{copyright}</p>
              <p>{address}</p>
              <p className='disclaimer'>
                {`All credit decisions are made by the lenders. The personal loan products on ${company} are from companies from which ${company} may receive compensation.
                Compensation may impact where products appear on the site (including the order in
                which they appear). ${company} does not include all personal loan companies
                or all types of products available in the marketplace.`}
              </p>
              <p className='disclaimer'>
                {`Personal Loans Rate and Terms Disclosure: Rates for personal loans provided by
                lenders on the platform range between 4.99-35.99% APR with terms from 24 to 84
                months. Actual rates may be different from the rates advertised and/or shown and
                will be based on the lender’s eligibility criteria, which include factors such as
                credit score, loan amount, loan term, credit usage and history, and vary based on
                loan purpose. The lowest rates available typically require excellent credit, and for
                some lenders, may be reserved for specific loan purposes and/or shorter loan terms.
                The origination fee charged by the lenders on our platform ranges from 0% to 8%. All
                rates are determined by the lender and must be agreed upon between the borrower and
                the borrower’s chosen lender. For a loan of $10,000 with a three year repayment
                period, an interest rate of 7.99%, a $350 origination fee and an APR of 11.51%, the
                borrower will receive $9,650 at the time of loan funding and will make 36 monthly
                payments of $313.32. Assuming all on-time payments, and full performance of all
                terms and conditions of the loan contract and any discount programs enrolled in
                included in the APR/interest rate throughout the life of the loan, the borrower will
                pay a total of $11,279.43. There is a possibility you may not be matched with any
                lenders. Loans are not available in all states at all requested amounts.
                ${company} only matches you with potential lenders and your inquiry on this
                website does not constitute a loan application.`}
              </p>
              <p className='disclaimer'>
                <strong>Consumer Notice:</strong>
                Cash advance loans are not a long-term financial solution. Borrowers facing debt and
                credit difficulties should seek professional financial advice. Borrowers are
                encouraged to review local laws and regulations governing personal loans.
              </p>
            </Container>
          </footer>
        </FooterWrapper>
      </div>
    );
  }
}

Footer.contextType = ConfigContext;

export default Footer;
