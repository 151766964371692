import styled from 'styled-components';
import CreditScoreImage from '../../assets/images/credit-score-illustration.png';

const CreditScoreWrapper=styled.div`
    &.credit-score-section {
        background-color:${props => props.themeColor};
        color:white;
        background-image: url(${CreditScoreImage});
        background-repeat:no-repeat;
        padding:50px 0;
        background-position: 25% bottom;
        
        h2 {
            margin-bottom:30px;
        }

        p {
            font-size:1.3em;
        }
    }

    @media (max-width: 1200px) {
        &.credit-score-section {
            padding:50px 0;
            background-position: 15% bottom;
            
            h2 {
                margin-bottom:20px;
            }

            p {
                font-size:1.2em;
            }
        }
    } 

    @media (max-width: 991px) {
        &.credit-score-section {
            background-position: 9% bottom;
            padding:40px 0;
            
            h2 {
                margin-bottom:20px;
            }

            p {
                font-size:1.1em;
            }
        }
    } 

    @media (max-width: 767px) {
        &.credit-score-section {
            padding:30px 0;
            background-position: 0% bottom;

            h2 {
                margin-bottom:20px;
            }

            p {
                font-size:1.0em;
            }

        }
    } 

    @media (max-width: 585px) {
        &.credit-score-section {
            padding:20px 0;
            background-image:none;

            .grid {
                .row {
                    .seven {
                        display:none;
                    }

                    .nine {
                        width:100% !important;
                    }
                }
            }
        }
    } 


`;

export default CreditScoreWrapper;