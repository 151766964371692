import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import PrivacyPolicyContentWrapper from './privacypolicycontent.style';

class PrivacyPolicyContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { company, address } = this.context;
    return (
      <PrivacyPolicyContentWrapper className='privacy-content'>
        <Container>
          <h1>Online Privacy Policy</h1>
          <p>Last Revised: January 3, 2020</p>
          <p>{`This website is owned and operated by ${company} (the “Company” or “${company}”). This online privacy policy (“Privacy Policy”) is intended to describe and explain to you, the end user, how ${company} handles its data collection, retention, use, distribution, and protection practices in connection with ${company} (the “Website”). This privacy policy is applicable only to www.${company} in connection with the data and information collected by this Website.`}</p>
          <p>
            <strong>Agreement to this Privacy Policy: </strong>
            By using the Company’s website, you expressly agree to the Company’s Terms of Service
            and this Privacy Policy and consent to the use of your information in the manner
            described herein.
            <strong>
              If you do not unconditionally agree with our Terms of Service and all of the
              provisions of this Privacy Policy, please do not submit any personal information to,
              or make any other use of, our Website.
            </strong>
          </p>
          <h3>How We Use the Information We Collect</h3>
          <p>
            {`${company} actively collects and stores only the information a user voluntarily provides to us, such as when you interact with our Website, submit a form, send us an email or message, sign up for our newsletter, or call us.  ${company} uses this information only in connection with our users’ requests, to provide you with the products and services you have requested through our Website, and to complete such requests and transactions for you. Any financial information provided to ${company} will be used to evaluate the user’s eligibility for the financial services they have requested or additional services they may qualify for. We may also send you notifications about important updates, enhancements, and/or changes to our services, newsletters, or other subscriptions you may have opted in to receive. We may also send you special offers, updates, and other information we believe may be of interest to you.`}
          </p>

          <h3>Children Privacy</h3>
          <p>
            {`The Children’s Online Privacy Protection Act (“COPPA”) protects the online privacy of
              children under 13 years of age. ${company} does not knowingly collect or
              maintain personal information from anyone under the age of 13, unless or as permitted
              by law. If we become aware that we have inadvertently received personal information
              from a visitor under the age of 13, we will delete the information from our records.
              If you are the parent or legal guardian of a child under 13 who has submitted
              information through the use of our Services, please contact us at`}
            <a href={`mailto:hello@${company.toLowerCase()}`}>
              {` hello@${company.toLowerCase()} `}
            </a>
            to have that child’s information deleted.
          </p>
          <p>
            <strong>
              By interacting with our Website in any way, you expressly represent and warrant that
              you are at least 18 years of age and you provide your consent to the data sharing
              practices described in this Privacy Policy. If you are under the age of 13, you are
              not authorized to use any Company Website or our Services.
            </strong>
          </p>
          <h3>Data and Information Sharing Practices with Third Parties</h3>
          <p>
            {`${company} also works with third parties. The information users provide to
              ${company} may be shared with`}
            <a href='/partners/'> third party financial service partners </a>
            {`to process the user’s
              information and further qualify the user for the services they have requested.
              ${company}’s third-party service provider partners are bound by
              confidentiality obligations and provide certain very specific services to users of the
              Website. When a user signs up for these services, ${company} shares the
              user’s contact and other personal information as necessary for the service provider to
              provide the requested services. Our partners are not permitted to use your personal
              information for any purpose other than providing the services that you have requested.`}
          </p>
          <h3>Your Choices Regarding the Use of Your Information</h3>
          <p>
            {`Unless you explicitly revoke the prior express consent, you gave during the signup
              process, you agree and consent to ${company} contacting you via email,
              landline or cellular phone or text message concerning specials, new promotions, new
              products, changes to this privacy policy or purely for advertising purposes. You may
              opt out of any future contact or marketing messages from ${company} at any
              time by contacting us at`}
            <a href={`mailto:hello@${company.toLowerCase()}`}>
              {` hello@${company.toLowerCase()} `}
            </a>
            {`. You may also
              review the data ${company} has collected about you, change or update your
              data, and request to have all data about you deleted. Please contact
              ${company} with any requests related to your data and privacy.`}
          </p>
          <p>
            If you consented to allow us to share your information with our marketing partners,
            affiliates or other affiliated entities by interacting with our Website and providing
            your consent, you will need to opt out of receiving communications from those marketing
            partners directly by visiting their websites and following the specific opt out
            instructions listed on their privacy policy for each type of communication you opted in
            to receive (Email/Text/SMS/Voicemail). Please note that, if you opt out of receiving
            emails from us, we may still send you email relating to future transactions you make
            with us, or emails about how you continue to use our services, or as described further
            elsewhere in this Privacy Policy.
          </p>
          <p>
            {`This Website may link to other websites. Note that ${company} is not
              responsible for the content, data retention policies or privacy practices of other web
              sites. Users that leave our Website should read the privacy policies of other websites
              which collect personally identifiable information. Users should be aware when they
              exit ${company} and it is recommended they read the privacy policies of any
              other website that collects personally identifiable information.`}
          </p>
          <h3>Security</h3>
          <p>
            {`At ${company}, the security of your personal information is extremely
              important to us. We take commercially reasonable precautions to protect your personal
              information and only provide employees access to it on a need-to-know basis. In an
              effort to prevent the unauthorized access, disclosure, dissemination, misuse, loss,
              alteration or destruction of your personal information, we have employed commercially
              reasonable physical, electronic, and managerial measures to protect your data and we
              periodically review these policies and procedures to prevent these types of acts.
              However, despite our best efforts, no security policy, procedures or practices are
              adequate to protect against all types of threats or data losses and no type of data
              transmission method is impenetrable to interception.`}
          </p>
          <p>
            {`As discussed above, when you submit personal and other information via
              ${company}’s online form, you are consenting to the distribution of your
              information to potential providers of financial services, as well as third party lead
              aggregators. Be aware that if you are connected with a service or lead provider, they
              may retain your personal information whether or not you use their services. Should you
              have any questions concerning how they are storing or using your personal information
              you should contact these service and lead providers directly regarding their privacy
              policy as it may differ from ${company}’s.`}
          </p>
          <p>
            {`You can access your personal information stored by ${company} or withdraw
              your consent to the collection and use of your information by contacting us directly
              at `}
            <a href={`mailto:hello@${company.toLowerCase()}`}>{`hello@${company.toLowerCase()}`}</a>
            {`. We will
              respond to your request within a reasonable time frame.`}
          </p>
          <p>
            {`${company} reserves the right to update or change their Privacy Policy at any
              time without any notice to you. We notify users that changes have been made to our
              Privacy Policy by updating the date it was last revised. Therefore,
              ${company} encourages you to review this Privacy Policy whenever you use any
              of the Company’s services or its Website in order to stay informed about the Company’s
              advertising and data collection practices related to information you share with us or
              that we may obtain from publicly available sources. By continuing to use the Company’s
              Services after we post any changes, you accept and agree to the modified Privacy
              Policy.`}
          </p>
          <h3>California Online Privacy Protection Act</h3>
          <p>
            CalOPPA is the first state law in the nation to require commercial websites and online
            services to post a privacy policy. The law’s reach stretches well beyond California to
            require a person or company in the United States (and conceivably the world) that
            operates websites collecting personally identifiable information from California
            consumers to post a conspicuous privacy policy on its website stating exactly the
            information being collected and those individuals with whom it is being shared, and to
            comply with this policy. – See more at:
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf'
            >
              http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
            </a>
            .
          </p>
          <p>Your Privacy Rights</p>
          <p>
            Individuals who reside in California and have provided their personal information may
            request information concerning disclosure of this information to third parties for
            direct marketing purposes. Such requests
            <strong> must </strong>
            be submitted to us at the following mailing address:
          </p>
          <p>{`${address}`}</p>
          <h3>Accessing or Modifying Your Information</h3>
          <p>
            You may also access or change your personal information at any time; provided, however,
            that we may reject requests that are unreasonably repetitive, require disproportionate
            technical effort, risk the privacy of others, or would be extremely impractical. We may
            also refuse a request to delete information if maintaining the information is required
            by law or is part of a fraud investigation, or as otherwise described in this Privacy
            Policy.
          </p>
          <p>
            {`You may access and modify your personal information by contacting ${company}
              at `}
            <a href={`mailto:hello@${company.toLowerCase()}`}>
              {` hello@${company.toLowerCase()} `}
            </a>
            .
          </p>
          <h3>California Do Not Track Disclosures</h3>
          <p>
            <strong>How do the Company’s websites handle “Do-Not-Track” signals? </strong>
            We do not respond to Web browser “do not track” (DNT) signals or other mechanisms that
            provide consumers the ability to exercise choice regarding the collection of personally
            identifiable information about an individual consumer’s online activities over time and
            across third-party websites or online services.
          </p>
          <p>
            <strong>Do the Company’s websites allow third-party behavioral tracking? </strong>
            No.
          </p>
          <p>
            <strong>
              {`Can other parties collect personally identifiable information about an individual
              consumer’s online activities over time and across different websites when they visit
              one of the Company’s websites? `}
            </strong>
            No.
          </p>
          <h3>Notice to California Residents</h3>
          <p>
            In response to California law, we will automatically treat individuals with California
            addresses or telephone numbers (when disclosed to us) as if you requested us to not
            share your information with nonaffiliated third parties except as permitted by the
            applicable California law, or unless you specifically opt-in and request that we share
            your information for the purpose you indicate on our website or other Service. We will
            also limit the sharing of information about you with our affiliates to comply with
            California privacy laws that apply to us, unless you specifically request that we share
            your information or request information on our website that may otherwise be provided by
            one of our marketing partners, affiliates or associated companies.
          </p>
          <p>
            {`With certain exceptions, residents of the State of California may request (a)
              disclosure of personal information collected, (b) disclosure of personal information
              sold or disclosed for a business purpose, (c) deletion of personal information, (d) to
              opt out of the sale of personal information, and (e) access and data portability.
              Moreover, ${company} will not discriminate based on your exercise of such
              rights. If you are a California resident and want to make any such requests, please
              contact us through our website (www.${company}) or`}
            <a href={`mailto:admin@${company.toLowerCase()}.com`}>
              {` admin@${company.toLowerCase()}.com `}
            </a>
            {`or at
              ${company} LLC, ${address}`}
          </p>
          <p>
            For all mailed requests, you must put the statement "California Privacy Request" in the
            body of your request, as well as your name, street address, city, state, and zip code.
            Please note that we are not responsible for requests that are not labeled or sent
            properly, or that do not have complete information.
          </p>
          <p>
            For all requests, you must put the statement “California Shine the Light Privacy
            Request” in the body of your request, as well as your name, street address, city, state,
            and zip code. Please note that we are not responsible for requests that are not labeled
            or sent properly, or that do not have complete information.
          </p>
          <p>
            California consumers may also click the following link to opt out of the sale of their
            personal information:
            <Link to='/do-not-sell/'> DO NOT SELL MY PERSONAL INFORMATION</Link>
          </p>
          <h3>Notice to Vermont Residents</h3>
          <p>
            In response to Vermont regulations, we automatically treat accounts with Vermont billing
            addresses as if you requested that we not share your information with nonaffiliated
            third parties, and that we limit the information we share with our affiliates, unless
            you provide your consent for us to share your personal information with our marketing
            partners. If we disclose information about you to nonaffiliated third parties with whom
            we have joint marketing agreements, we will only disclose your name, address, other
            contact information, and information about our transaction and experiences with you.
          </p>
          <h3>Notice to Nevada Residents</h3>
          <p>
            We are providing you this notice pursuant to state law. You may be placed on our
            internal Do Not Call List by requesting that we cease calling you by contacting us
            directly and making such request in writing to
            <a href='mailto:privacy@getalowerpayment.com'> privacy@getalowerpayment.com</a>
            {`. Nevada
            law requires that we also provide you with the following contact information:`}
          </p>
          <p>Bureau of Consumer Protection</p>
          <p>Office of the Nevada Attorney General</p>
          <p>555 E. Washington St., Suite 3900</p>
          <p>Las Vegas, NV 89101</p>
          <p>Phone number: 702-486-3132</p>
          <p>
            E-mail:
            <a href='mailto:BCPINFO@ag.state.nv.us'> BCPINFO@ag.state.nv.us</a>
          </p>
          <h3>Notice to Users Outside of the United States</h3>
          <p>
            This Online Privacy Policy is intended to cover collection, processing, transmission,
            dissemination, analysis, copying and appending of information on our Services from
            residents of the United States. If you visit one of our websites, mobile apps, or
            interact with one of our other digital services from outside of the United States,
            please be aware that you are providing consent that your information may be transmitted
            to, stored, stored, copied, backed up and otherwise processed in the United States where
            our servers and system databases are located. The data protection, privacy laws and
            other laws of the United States and other countries which may pertain to your
            interaction with our Services might not be as comprehensive as those in your country.
            Therefore, by visiting our websites or otherwise interacting with our Services, you
            understand that your information may be transferred to our servers and databases which
            are housed in facilities located in the United States and which may be shared with
            third-parties with whom we share data and you therefore provide your express consent to
            our Privacy Policy, Terms of Use, and data sharing practices as described in this
            Notice.
          </p>
          <h3>To Contact Us</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy, you may contact us by
            email at
            <a href={`mailto:hello@${company.toLowerCase()}`}>
              {` hello@${company.toLowerCase()} `}
            </a>
            .
          </p>
          <p>
            <strong>
              PLEASE PRINT AND RETAIN A COPY OF THIS DIGITAL ONLINE PRIVACY POLICY FOR YOUR RECORDS.
            </strong>
          </p>
        </Container>
      </PrivacyPolicyContentWrapper>
    );
  }
}

PrivacyPolicyContent.contextType = ConfigContext;

export default PrivacyPolicyContent;
