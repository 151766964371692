import React from 'react';
import CreditScoreWrapper from './creditscore.style';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import { Container, Grid } from 'semantic-ui-react';

class CreditScore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { themeColor } = this.context;

    return (
      <div className="App">
        <CreditScoreWrapper className="credit-score-section" themeColor={themeColor}>
          <Container>
            <Grid columns='two'>
              <Grid.Row>
                <Grid.Column width={7}>
                  
                </Grid.Column>
  
                <Grid.Column width={9}>
                  <h2>Whether You Have Good or Bad Credit, We Can Help </h2>
                  <p>Less-than-perfect credit doesn’t have to hold you back! We work with a range of top-rated lenders, many of whom specialize in helping people overcome credit challenges to get the financing they need. We can help you explore all your options, regardless of your credit score.</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </CreditScoreWrapper>
  
      </div>
    );
  }
}

CreditScore.contextType = ConfigContext;

export default CreditScore;