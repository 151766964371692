const loanPurpose = [
  { copy: 'Debt Consolidation', icon: 'fal fa-layer-group', purpose: 'Debt%20Consolidation' },
  { copy: 'Emergency', icon: 'fal fa-map-marker-exclamation', purpose: 'Emergency' },
  {
    copy: 'Special Events Expenses',
    icon: 'fal fa-calendar-alt',
    purpose: 'Special%20Events%20Expenses'
  },
  { copy: 'Taxes', icon: 'fal fa-university', purpose: 'Taxes' },
  { copy: 'Baby/Adoption', icon: 'fal fa-baby', purpose: 'Baby/Adoption' },
  {
    copy: 'Credit Card Consolidation',
    icon: 'fal fa-credit-card',
    purpose: 'Credit%20Card%20Consolidation'
  },
  { copy: 'Household Expense', icon: 'fal fa-home-lg-alt', purpose: 'Household%20Expense' },
  {
    copy: 'Moving & Relocation',
    icon: 'fal fa-truck-couch',
    purpose: 'Moving%20%26%20Relocation'
  },
  { copy: 'Vacation', icon: 'fal fa-island-tropical', purpose: 'Vacation' },
  { copy: 'Motorcycle', icon: 'fal fa-motorcycle', purpose: 'Motorcycle' },
  { copy: 'Auto', icon: 'fal fa-car', purpose: 'Auto' },
  { copy: 'Major Purchase', icon: 'fal fa-shopping-cart', purpose: 'Major%20Purchase' },
  { copy: 'Green', icon: 'fal fa-leaf', purpose: 'Green' },
  { copy: 'RV', icon: 'fal fa-rv', purpose: 'RV' },
  { copy: 'Home Improvement', icon: 'fal fa-wrench', purpose: 'Home%20Improvement' },
  { copy: 'Medical/Dental', icon: 'fal fa-notes-medical', purpose: 'Medical/Dental' },
  { copy: 'Auto Repair', icon: 'fal fa-car-mechanic', purpose: 'Auto' },
  { copy: 'Boat', icon: 'fal fa-anchor', purpose: 'Boat' },
  { copy: 'Other', icon: 'fal fa-map-marker-question', purpose: 'Personal' }
];

export default loanPurpose;
