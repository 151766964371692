const createChunks = (array, size) => {
  const copy = array.concat();
  const chunks = [];

  while (copy.length) {
    chunks.push(copy.splice(0, size));
  }

  return chunks;
};

export default createChunks;
