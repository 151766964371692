import styled from 'styled-components';

const FormWrapper = styled.div`
  #choices {
    position: relative;
    .select-container {
      display: flex;
      width: 77%;
      margin: 0 auto;
      .select {
        background-color: white;
        padding: 25px;
        font-size: 1.5em;
        color: #7d7d7d;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        width: 73%;
        .svg-inline--fa {
          float: right;
          color: ${props => props.themeColor};
        }
      }
      .select-btn {
        background-color: ${props => props.themeColor};
        font-size: 1.5em;
        padding: 25px 15px;
        color: white;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        width: 27%;
        text-align: center;
      }
    }
    .dropdown-container {
      position: absolute;
      background-color: white;
      width: 100%;
      border-radius: 4px;
      padding: 15px 20px;
      border: 1px solid #ededed;
      display: flex;
      -webkit-box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.29);
      -moz-box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.29);
      box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.29);
      z-index: 90;
      display: ${props => (props.showdropdown ? 'flex' : 'none')};
      .dropdown-column {
        width: 25%;
        .dropdown-item {
          &:last-of-type {
            a {
              border-bottom: 0;
            }
          }
          a {
            padding: 15px;
            display: block;
            border-bottom: 1px solid #ededed;
            color: #2a353d;
            font-size: 1em;
            -webkit-transition: all 0.1s;
            transition: all 0.1s ease;
            .svg-inline--fa {
              margin-right: 10px;
              color: #44accd;
            }
            &:hover {
              background-color: #44accd;
              color: white;

              .svg-inline--fa {
                color: white;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1200px) {
    #choices {
      .select-container {
        width: 90%;
      }
      .dropdown-container {
        .dropdown-column {
          .dropdown-item {
            a {
              padding: 11px;
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    #choices {
      .select-container {
        width: 100%;

        .select {
          padding: 20px;
          font-size: 1.3em;
        }
        .select-btn {
          font-size: 1.3em;
          padding: 20px 15px;
        }
      }
      .dropdown-container {
        width: 128%;
        padding: 10px 20px;
        margin-left: -14%;

        .dropdown-column {
          .dropdown-item {
            a {
              padding: 8px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 895px) {
    #choices {
      .dropdown-container {
        width: 120%;
        margin-left: -10%;

        .dropdown-column {
          .dropdown-item {
            a {
              padding: 6px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 835px) {
    #choices {
      display: none;
    }
  }
`;

export default FormWrapper;
