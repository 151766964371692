import styled from 'styled-components';

const HowItWorksWrapper = styled.div`
  overflow: hidden;
  &.how-it-works-section {
    padding: 50px 0 80px;
    text-align: center;
    h2 {
      font-size: 2.5em;
      margin-bottom: 45px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        display: inline-block;
        width: 25%;
        text-align: center;
        font-size: 25px;
        position: relative;
        &:before {
          content: '';
          border: 1px solid #2a353d;
          position: absolute;
          display: block !important;
          top: 39px;
          left: 50%;
          z-index: -1;
          width: 90%;
        }
        &:last-of-type {
          &:before {
            border: none;
          }
        }
        .circle {
          border: 2px solid #44accd;
          border-radius: 50%;
          width: 80px;
          height: 80px;
          margin: 0 auto;
          font-size: 30px;
          line-height: 75px;
          margin-bottom: 15px;
          background-color: white;
        }
        p {
          span {
            font-weight: 400;
            display: block;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    &.how-it-works-section {
      ul {
        li {
          font-size: 23px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    &.how-it-works-section {
      padding: 30px 0 50px;

      h2 {
        font-size: 2.3em;
        margin-bottom: 35px;
      }

      ul {
        li {
          font-size: 20px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.how-it-works-section {
      padding: 30px 0 50px;

      h2 {
        font-size: 2.1em;
      }

      ul {
        li {
          font-size: 18px;

          &:before {
            top: 28px;
          }

          .circle {
            width: 60px;
            height: 60px;
            line-height: 55px;
          }
        }
      }
    }
  }

  @media (max-width: 672px) {
    &.how-it-works-section {
      padding: 30px 0 50px;

      h2 {
        font-size: 2.1em;
      }

      ul {
        li {
          font-size: 18px;
          width: 50%;
          margin-bottom: 30px;

          &:before {
            display: none;
          }

          .circle {
            width: 60px;
            height: 60px;
            line-height: 55px;
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    &.how-it-works-section {
      padding: 20px 0 50px;

      h2 {
        font-size: 2.1em;
      }

      ul {
        li {
          font-size: 18px;
          width: 100%;
          margin-bottom: 30px;

          &:before {
            display: none;
          }

          .circle {
            width: 60px;
            height: 60px;
            line-height: 55px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export default HowItWorksWrapper;
