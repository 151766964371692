import React from 'react';
import GlobalStyle from '../assets/global.style';
import Header from '../components/Header/header';
import MobileForm from '../components/MobileForm/mobileform';
import HowItWorks from '../components/HowItWorks-comp/howitworks';
import CreditScore from '../components/CreditScore-comp/creditscore';
import GeneralCTA from '../components/GeneralCTA-comp/generalcta';
import Footer from '../components/Footer-comp/footer';

function Homepage() {
  return (
    <div className='App'>
      <GlobalStyle />
      <Header />
      <MobileForm />
      <HowItWorks />
      <CreditScore />
      <GeneralCTA />
      <Footer />
    </div>
  );
}

export default Homepage;
