import React from 'react';
import FormWrapper from './form.style';
import loanPurpose from './formData';
import { getAllParameters } from '../../utils/getUrlParams';
import arrayChuncker from '../../utils/arrayChuncker';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';

class Form extends React.Component {
  state = {
    dropdown: false
  };

  renderLoanPurposes = (data, urlParameters) =>
    arrayChuncker(data, 5).map((chunk, index) => (
      <div className='dropdown-column' key={`column_${index}`}>
        {chunk.map(({ copy, icon, purpose }) => (
          <div className='dropdown-item' key={copy}>
            <a
              href={`loan-application?loanPurpose=${purpose}&urlParameters=${encodeURIComponent(
                JSON.stringify(urlParameters)
              )}`}
            >
              <i className={icon} />
              {copy}
            </a>
          </div>
        ))}
      </div>
    ));

  toggleDropdown = () => {
    const dropdown = !this.state.dropdown;
    this.setState({ dropdown });
  };

  render() {
    const { themeColor } = this.context;
    const { dropdown } = this.state;
    const urlParameters = getAllParameters();
    return (
      <div className='Form'>
        <FormWrapper showdropdown={dropdown} minWidth={835} themeColor={themeColor}>
          <div id='choices'>
            <div className='select-container' onClick={this.toggleDropdown}>
              <div className='select'>
                {`What are you looking to do with this loan? `}
                <i className='fal fa-chevron-down' />
              </div>
              <div className='select-btn'>See Rates Now</div>
            </div>
            <div className='dropdown-container'>
              {this.renderLoanPurposes(loanPurpose, urlParameters)}
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

Form.contextType = ConfigContext;

export default Form;
