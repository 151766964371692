import styled from 'styled-components'
const GeneralCTAWrapper=styled.div`
    &.generalCTA {
        padding:75px 0;

        h2 {
            margin-bottom:30px;
        }

        p {
            font-size:1.3em;
        }
        .button {
            font-size:1.4em;
            font-weight:100;
            background-color: ${props => props.themeColor}
        } 
    }

    @media (max-width: 1200px) {
        &.generalCTA {
            padding:50px 0;
    
            h2 {
                margin-bottom:20px;
            }
    
            p {
                font-size:1.2em;
            }
            .button {
                font-size:1.3em;
                margin-top:15px;
            } 
        }
    }

    @media (max-width: 991px) {
        &.generalCTA {
            padding:40px 0;
    
            h2 {
                margin-bottom:20px;
            }
    
            p {
                font-size:1.1em;
            }
            .button {
                font-size:1.2em;
                margin-top:10px;
            } 
        }
    }

    @media (max-width: 585px) {
        &.generalCTA {

            .grid {
                .row {
                    .seven {
                        width:100% !important;
                        text-align:center;
                    }

                    .nine {
                        width:100% !important;
                        text-align:center;
                        margin-bottom:25px;
                    }
                }
            }
        }
    } 
`;

export default GeneralCTAWrapper;