// Parsing URL params
function getUrlParameter(name) {
  const newName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${newName}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function getAllParameters() {
  const queryString = decodeURIComponent(window.location.search.substring(1)).split('&');
  const paramsObj = {};
  if (queryString.length > 0) {
    queryString.map(param => {
      if (param) {
        const keyparam = param.split('=')[0].toLowerCase();
        paramsObj[keyparam] = param.split('=')[1];
      }
    });
  }
  return paramsObj;
}

export { getUrlParameter, getAllParameters };
