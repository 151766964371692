import React from 'react';
import { Container } from 'semantic-ui-react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import TermsConditionsContentWrapper from './termsconditionscontent.style';

class TermsConditionsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { company } = this.context;
    return (
      <TermsConditionsContentWrapper className='terms-content'>
        <Container>
          <h1>Online Terms & Conditions</h1>
          <p>Last Revised: January 3rd, 2020</p>
          <h2>{`AGREEMENT BETWEEN USER AND ${company.toUpperCase()}`}</h2>
          <p>
            {`${company} (“${company} or the “Company”), the owner and operator of
            this Website, provides its website visitors with access to its content, resources,
            tools, public forums, and other services through its network of websites located at`}
            <a href='/'>{` ${window.location.hostname} `}</a>
            (the “Website”).
            {` ${company}
            provides its services to all visitors and users of the Website subject to the following
            Terms and Conditions.`}
          </p>
          <p>
            These Terms and Conditions govern your use of the Website. Access to, and use of, this
            Website and the products and services available through this Website (collectively, the
            “Services”) are subject to the following terms, conditions, and notices (the “Terms of
            Service”). This Website is offered to you conditioned on your unqualified acceptance
            without modification of the Terms of Service.
          </p>
          <p>
            <strong>
              By using the Services, you are expressly agreeing to all of the Terms of Service. If
              you do not unconditionally agree with these Terms of Service or our Online Privacy
              Policy, you are not authorized to access or use the Website and you must immediately
              discontinue your use of the Website and exit it.
            </strong>
          </p>
          <h3>
            <strong>Conditions of Use</strong>
          </h3>
          <p>
            THESE TERMS OF SERVICE APPLY TO ALL MATERIALS, ONLINE COMMUNICATIONS, AND OTHER
            INFORMATION AND MATERIALS THAT ARE OR BECOME AVAILABLE ON OR THROUGH THE WEBSITE. PLEASE
            READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING THE WEBSITE YOU SPECIFICALLY
            ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND YOU SPECIFICALLY AGREE,
            WITHOUT LIMITATION OR QUALIFICATION, TO EACH OF THE PROVISIONS CONTAINED HEREIN. THESE
            TERMS OF SERVICE MAY BE REVISED AT ANY TIME, NOTICE OF WHICH WILL BE PROVIDED BY
            UPDATING THE “LAST REVISED” DATE ABOVE. YOU ARE BOUND BY ANY SUCH REVISIONS AND SHOULD
            THEREFORE PERIODICALLY VISIT THIS PAGE TO REVIEW THE THEN-CURRENT TERMS OF SERVICE TO
            WHICH YOU ARE BOUND.
          </p>
          <p>
            Access to this Website is permitted on a temporary basis, and we reserve the right to
            terminate your use of this Website for any reason or no reason including, without
            limitation, if we learn that you have violated these Terms of Service. We may also, at
            our sole discretion, withdraw or amend the Services without any notice to you. We will
            not be liable if for any reason this Website is unavailable at any time or for any
            period. From time to time, we may restrict access to some or all of this Website.
          </p>
          <h3>
            <strong>Linked Sites</strong>
          </h3>
          <p>
            {`This Website may contain links to other third party websites (the “Linked Sites”), which
            are not operated by the Company. ${company} has no control over the Linked
            Sites and accepts no responsibility for them or their contents, or for any loss or
            damage that may arise from your use of the Linked Sites. These Terms of Service are not
            applicable to the Linked Sites. Your use of the Linked Sites will be subject to each
            such Linked Site’s terms of use and service.`}
          </p>
          <p>
            <strong>
              {`The Company is not a lender or loan broker: Please note the following if you are
              applying for a loan through a lender to which you were matched through the use of this
              website: `}
            </strong>
            {`The operator of this Website is not a lender, does not broker loans to lenders, and does
            not make personal loans or credit underwriting decisions. This Website does not
            constitute an offer or solicitation to lend. This Website will submit the information
            you provide to a lender or lenders. Providing your information on this website does not
            guarantee that you will be approved for a loan. The operator of this Website is not an
            agent, representative, or broker of any lender and does not endorse any particular
            lender. ${company} does not charge you for any service or product.`}
          </p>
          <h3>
            <strong>Online Privacy Policy</strong>
          </h3>
          <p>
            Our Online Privacy Policy sets out how we collect, share, and use your information and
            can be found
            <a href='/privacy-policy/'> here. </a>
            By using this Website, you consent to the treatment of your information as described
            therein and warrant that all data provided by you is accurate. Please review our Online
            Privacy Policy before using the website
          </p>
          <h3>
            <strong>Prohibitions: No Unlawful or Prohibited Use</strong>
          </h3>
          <p>
            As a condition of your authorization to use this Website, you warrant that you will not
            do any of the following: commit or encourage a criminal offense; transmit or distribute
            a virus, trojan horse, worm, logic bomb, time bomb, or any other material which is
            malicious, technologically harmful, in breach of confidence or in any way offensive or
            obscene; hack into any aspect of the Service; corrupt data; cause annoyance to other
            users; infringe upon the rights of any other person’s proprietary rights; send any
            unsolicited advertising or promotional material, commonly referred to as “spam”; or
            attempt to affect the performance or functionality of any computer facilities of or
            accessed through this Website. Breaching this provision could constitute a criminal
            offense and the Company reserves the right to report any such breach to the relevant law
            enforcement authorities.
          </p>
          <p>
            We will not be liable for any loss or damage caused by a distributed denial-of-service
            attack, viruses or other technologically harmful material that may infect your computer
            equipment, computer programs, data or other proprietary material due to your use of this
            Website or to your downloading of any material posted on it, or on any website linked to
            it.
          </p>
          <h3>
            <strong>Intellectual Property, Software and Content</strong>
          </h3>
          <p>
            {`With the exception of third-party content as referenced above, the intellectual property
            rights in all software and content (including photographic images) made available to you
            on or through this Website remains the property of the Company or its licensors and are
            protected by copyright laws and treaties around the world. All such rights are reserved
            by ${company} and its licensors. You may store, print and display the content
            supplied solely for your own personal use. You are not, without the express written
            permission of ${company}, or with respect to third party content, its
            respective owner, permitted to publish, manipulate, distribute or otherwise reproduce,
            in any format, any of the content or copies of the content supplied to you or which
            appears on this Website nor may you use any such content in connection with any business
            or commercial enterprise.`}
          </p>
          <h3>
            <strong>Disclaimer of Liability</strong>
          </h3>
          <p>
            <strong>
              {`You expressly agree that use of this Website is at your sole risk. The material
              displayed on this Website is provided on an “as-is” and “as available” basis, without
              any guarantees, conditions or warranties as to its accuracy. To the fullest extent
              permitted by law ${company} and its suppliers, content providers and
              advertisers hereby expressly disclaim all conditions, warranties of any kind,
              including without limitation, warranties of title, non-infringement, or implied
              warranties of merchantability or fitness for a particular purpose with respect to the
              Website, any use of the Website, and any other website to which the Website is linked,
              whether express or otherwise implied by any source, including, but not limited to,
              statute, common law, or the law of equity.`}
            </strong>
          </p>
          <p>
            WHILE THE COMPANY USES REASONABLE EFFORTS TO INCLUDE ACCURATE AND UP-TO-DATE INFORMATION
            ON THE WEBSITE, ERRORS OR OMISSIONS MAY SOMETIMES OCCUR.
          </p>
          <p>
            YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO CIRCUMSTANCES
            SHALL THE COMPANY, OR ITS OFFICERS, EMPLOYEES, DIRECTORS, MANAGERS, PARENTS,
            SUBSIDIARIES, AFFILIATES, AGENTS OR LICENSORS, BE LIABLE FOR ANY DIRECT, INDIRECT,
            INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT MAY RESULT FROM THE USE OR
            INABILITY TO USE THIS WEBSITES, OR THE MATERIAL, INCLUDING WITHOUT LIMITATION, USE OF OR
            RELIANCE ON THE MATERIAL, INTERRUPTIONS, ERRORS, DEFECTS, MISTAKES, OMISSIONS, DELETION
            OF FILES, DISABLING DEVICES, DELAYS IN OPERATION OR TRANSMISSION, NONDELIVERY OF
            INFORMATION, DISCLOSURE OF COMMUNICATIONS, OR ANY OTHER FAILURE OF PERFORMANCE, DAMAGES
            FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES ARISING
            OUT OF OR RELATED TO YOUR USE OF THE WEBSITES, REGARDLESS OF WHETHER SUCH DAMAGES ARE
            BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), WARRANTY, STATUTE
            OR OTHERWISE. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE WEBSITE, THE SOLE AND
            EXCLUSIVE REMEDY AVAILABLE TO YOU IS TO DISCONTINUE USE OF THE WEBSITE.
          </p>
          <p>
            YOU (AND NOT THE COMPANY OR ITS PARENTS OR AFFILIATES) SOLELY ASSUME THE ENTIRE COST OF
            ALL NECESSARY SERVICING, REPAIR, OR CORRECTION RELATED TO ANY DAMAGE, HARM, INJURY OR
            MALFUNCTION ASSOCIATED WITH YOUR USE OF THE WEBSITE AND/OR ITS CONTENTS.
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of certain warranties or the limitation or
            exclusion of liability for incidental or consequential damages. Accordingly, some of the
            above limitations and disclaimers may not apply to you. To the extent that the Company
            may not, as a matter of applicable law, disclaim any implied warranty or limit its
            liabilities, the scope and duration of such warranty and the extent of our liability
            shall be the minimum permitted under such applicable law.
          </p>
          <h3>
            <strong>Linking to this Website</strong>
          </h3>
          <p>
            You may link to our homepage, provided you do so in a way that is fair and legal and
            does not damage our reputation or take advantage of it, but you must not establish a
            link in such a way as to suggest any form of association, approval or endorsement on our
            part where none exists. You must not establish a link from any website that is not owned
            by you. This Website must not be framed on any other site, nor may you create a link to
            any part of this Website other than the homepage. We reserve the right to withdraw
            linking permission without notice.
          </p>
          <h3>
            <strong>Copyright and Trademark Notices</strong>
          </h3>
          <p>
            {`Disclaimer as to ownership of trade marks, images of personalities and third party
            copyright except where expressly stated to the contrary: all persons (including their
            names and images), third party trademarks and content, services and/or locations
            featured on this Website are in no way associated, linked or affiliated with The Company
            and you should not rely on the existence of such a connection or affiliation. Any trade
            marks/names featured on this Website are owned by the respective trademark owners. Where
            a trade mark or brand name is referred to it is used solely to describe or identify the
            products and services and is in no way an assertion that such products or services are
            endorsed by or connected to ${company}.`}
          </p>
          <h3>
            <strong>Copyright Infringement Policy</strong>
          </h3>
          <p>
            {`In accordance with the requirements set forth in the Digital Millennium Copyright Act,
            Title 17 United States Code Section 512(c)(2) (“DMCA”), ${company} will
            investigate notices of copyright infringement and take appropriate remedial action. If
            you believe that any content on the Website has been used or copied in a manner that
            infringes your work, please provide a written notification of claimed copyright
            infringement to the Designated Agent for the Website containing the following elements
            as set forth in the DMCA:`}
          </p>
          <ul>
            <li>
              a physical or electronic signature of the owner of the copyright interest that is
              alleged to have been infringed or the person authorized to act on behalf of the owner;
            </li>
            <li>
              identification of the copyrighted work(s) claimed to have been infringed, including
              copyright date;
            </li>
            <li>
              identification of the content you claim to be infringing and which you request be
              removed from the Website or access to which is to be disabled along with a description
              of where the infringing content is located;
            </li>
            <li>
              information reasonably sufficient to allow us to contact you, such as a physical
              address, telephone number, and an email address;
            </li>
            <li>
              a statement by you that you have a good faith belief that the use of the content
              identified in your written notification in the manner complained of is not authorized
              by you or the copyright owner, its agent or the law; and a statement by you that the
              information in your written notification is accurate and that, under penalty of
              perjury, you are the copyright owner or authorized to act on behalf of the copyright
              owner.
            </li>
          </ul>
          <p>
            The Company’s designated agent for the written notification of claims of copyright
            infringement can be contacted at the following address:
          </p>
          <p>Designated Agent – Copyright Infringement Claims</p>
          <p>{company}</p>
          <p>c/o Greenspoon Marder, Attention: Robby H. Birnbaum, Esq.</p>
          <p>Trade Centre South, Suite 700</p>
          <p>100 W. Cypress Creek Road</p>
          <p>Fort Lauderdale, Florida 33309-2140</p>
          <p>Phone: (954) 491-1120</p>
          <p>
            Email:
            <a href='mailto:Robby.Birnbaum@gmlaw.com'> Robby.Birnbaum@gmlaw.com </a>
          </p>
          <h3>
            <strong> </strong>
            <strong>Indemnity</strong>
          </h3>
          <p>
            {`You agree to indemnify, defend and hold harmless ${company}, its parents,
            subsidiaries, directors, officers, employees, consultants, agents, and affiliates, from
            and against any and all claims, liabilities, damages, losses, costs, and expenses
            (including, but not limited to, legal fees) arising from your use this Website or your
            breach of any applicable law or the Terms of Service.`}
          </p>
          <h3>
            <strong>Variation</strong>
          </h3>
          <p>
            {`${company} shall have the right in its absolute discretion at any time and
            without notice to amend, remove or vary the Services and/or any page of this Website.`}
          </p>
          <h3>
            <strong>Invalidity</strong>
          </h3>
          <p>
            If any part of the Terms of Service is held to be unenforceable (including any provision
            in which we limit or disclaim our liability) the enforceability of any other part of the
            Terms of Service will not be affected all other clauses remaining in full force and
            effect. To the extent possible, where any clause/sub-clause or part of a
            clause/sub-clause can be severed to render the remaining part valid, the clause shall be
            interpreted accordingly. Alternatively, you agree that the clause shall be rectified and
            interpreted in such a way that most closely resembles the original meaning of the clause
            /sub-clause as is permitted by law.
          </p>
          <h3>
            <strong>Complaints</strong>
          </h3>
          <p>
            We operate a complaints-handling procedure which we will use to try to resolve disputes
            when they first arise. Please let us know if you have any complaints or comments by
            contacting us at 1-855-852-5333 or by email at
            <a href={`mailto:contact@${company.toLowerCase()}`}>
              {` contact@${company.toLowerCase()}`}
            </a>
            .
          </p>
          <strong>Waiver</strong>

          <p>
            {`If you breach these Terms of Service and the Company exercises its right to take no
            action, such exercise does not constitute a waiver of any of ${company}’s
            future rights, and the Company reserves the right to avail itself of any and all
            remedies available to it, including, but not limited to, termination of your access to
            the Website in any other situation where you breach the letter or spirit of these Terms
            of Service. You agree that the Company shall not be liable to you or any other party for
            any termination of your access to the Website. The provisions of these Terms of Service
            shall survive any termination of your access to the Website or Services.`}
          </p>
          <p>
            {`${company} expressly reserves the right to seek any remedy available at law or
            in equity for any violation of these Terms of Service, including the right to block
            access from a particular Internet address to the Website, and irreparable harm will be
            presumed by any violation of these Terms and Conditions of Use.`}
          </p>
          <h3>
            <strong>Entire Agreement</strong>
          </h3>
          <p>
            The above Terms of Service constitute the entire agreement of the parties and supersede
            any and all preceding and contemporaneous agreements between you and the Company.
          </p>
        </Container>
      </TermsConditionsContentWrapper>
    );
  }
}

TermsConditionsContent.contextType = ConfigContext;

export default TermsConditionsContent;
